export default [
  'preserve oceans',
  'feed children',
  'build libraries',
  'send emergency aid',
  'train entrepreneurs',
  'plant trees',
  'prevent disease',
  'give clean water',
  'protect human rights',
]
